
































html
  background-color white
.q-page
  background-image url('~assets/bp-login-bg.jpg')
  background-size: cover
  background-position: top
.login-layout
  min-width 425px
  background-color #51abe2
  &:before,
  &:after
    content ''
    position fixed
    top 0
    bottom 0
    left 0
    right 0
    z-index 0
  &:before
    background-position top right
    background-image linear-gradient(45deg, #2a98db 0%, #51abe200 100%);
    background-repeat repeat-y
  &:after
    background-image url('~assets/worldmapwithpoints.svg')
    background-position top center
    background-repeat no-repeat
    background-size 1024px
  >>> .q-btn.text-white
    color #fff !important
.container
.worldmap
  top 0
  z-index -1
  opacity .6
