


















@import '../../../styles/login.styl'
.login-img
  margin 0 15px
  width calc(100% - 60px)
@media screen and (min-width: 641px)
  .login-grid
    overflow-y scroll
    grid-template-columns minmax(390px, 30%) auto
    background-image url('~assets/heart/login.png')
    background-size cover
    background-repeat no-repeat
@media screen and (max-width: 641px)
  .login-side
    grid-template-rows none
  .login-img
    width calc(100% - 60px)
