html {
  background-color: #fff;
}
.q-page {
  background-image: url("~assets/bp-login-bg.jpg");
  background-size: cover;
  background-position: top;
}
.login-layout {
  min-width: 425px;
  background-color: #51abe2;
}
.login-layout:before,
.login-layout:after {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}
.login-layout:before {
  background-position: top right;
  background-image: linear-gradient(45deg, #2a98db 0%, rgba(81,171,226,0) 100%);
  background-repeat: repeat-y;
}
.login-layout:after {
  background-image: url("~assets/worldmapwithpoints.svg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 1024px;
}
.login-layout >>> .q-btn.text-white {
  color: #fff;
}
.container,
.worldmap {
  top: 0;
  z-index: -1;
  opacity: 0.6;
}
/*# sourceMappingURL=src/layouts/login/bp/index.css.map */